import React from "react";
import Image from "components/shared/Image";
import { Tooltip } from "components/shared/Tooltip";
import ArrowDown from "assets/images/arrow-down.svg";
import TipIcon from "assets/images/TipIcon.svg";
import knowledge from "assets/images/KnowledgeResearch.svg";
import analytics from "assets/images/Analytics.svg";
import designservices from "assets/images/DesignService.svg";
import otherservices from "assets/images/OtherGroups.svg";
import CommercialExcellence from "assets/images/HelpfulResource.svg";
import legal from "assets/images/LegalServices.svg";
import itconnect from "assets/images/ITConnect.svg";
import aigpt from "assets/images/aigpt.svg";
import NewServiceBadge from "assets/images/NewServiceBadge.svg";
import ReactHtmlParser from 'html-react-parser';
import { saveMFU } from "redux/actions/allservices/allservices.saga";
import { sendAdobeAnaltics } from "utils/helpers/helpers";

import "./Service.scss";

export const Service = ({ data, serviceClick, serviceRef, index, subServiceRefs }) => {

    const buttonClick = async (serviceId, buttonText, subCategory, category) => {
        await saveMFU(serviceId);
        await sendAdobeAnaltics(buttonText, subCategory, category, "OP_CATEGORY_BUTTON_EVENTS", "All Services");
    }

    const buttonClickForEmail = async (linkUrl, serviceId, buttonText, subCategory, category) => {
        eval(linkUrl);
        buttonClick(serviceId, buttonText, subCategory, category);
    }

    const serviceIcon = (name) => {
        switch (name) {
            case "Knowledge and Research":
                return knowledge;
            case "Analytics":
                return analytics;
            case "Design Services":
                return designservices;
            case "Commercial Excellence":
                return CommercialExcellence;
            case "Legal and Risk":
                return legal;
            case "IT Connect":
                return itconnect;
            case "AI/GPT":
                return aigpt;
            default:
                return otherservices;
        }
    };
    return (
        <div>
            {
                data.multipleServices !== true ?
                    <div className="service">
                        <div class="heading">
                            <Image className="service-icon" src={serviceIcon(data.name)} alt="service icon" />
                            <span class="title">{data.name} ({data.services.length})</span>
                            {
                                data.tooltipInfo !== "" && data.tooltipInfo !== null && <div class="custom-tooltip">
                                    <Image data-tip data-for={data.id} className="tool-tip-icon" src={TipIcon} alt="Tool Tip Icon" />
                                </div>
                            }
                            <Tooltip className={""} id={data.id} delayHide={0} position="top">{data.tooltipInfo}</Tooltip>
                        </div>
                        <div className="sub-services" ref={serviceRef}>
                            {data.services.map((service, subServiceIndex) => (
                                <React.Fragment key={service.id}>

                                    <div style={{ display: service.isExpanaded !== true ? 'flex' : 'none' }} className={`${service.highlightFlag === 1 ? "sub-service highlighted" : "sub-service"}`} onClick={() => serviceClick(service.id, index, subServiceIndex, 1, data.id, service.name, data.name)}>
                                        {service.highlightFlag === 1 && <Image className="new-service-badge" src={NewServiceBadge} alt="new-service-badge icon" />}
                                        <div class="sub-service-title">{service.name}</div>
                                        <Image className="arrow-down" src={ArrowDown} alt="Arrow Down" />
                                    </div>

                                    <div style={{ display: service.isExpanaded === true ? 'flex' : 'none' }} className="sub-service-expanded" ref={subServiceRefs[subServiceIndex]}>
                                        <div class="sub-service-header" onClick={() => serviceClick(service.id, index, subServiceIndex, 1, data.id)}>
                                            <span class="header-text">{service.name}</span>
                                        </div>
                                        <div class="sub-service-content">
                                            <div class="sub-service-description">{ReactHtmlParser(service.description)}</div>
                                            <div class="sub-service-links">
                                                <div className="left-button-container">
                                                    {service.links.filter(link => link.position === "Left")
                                                        .map(link => (
                                                            <>
                                                                {
                                                                    link.linkUrl.indexOf("javascript:window.open") === -1 ?
                                                                        <a class="learn-button" rel="noopener noreferrer" href={link.linkUrl} target="_blank" onClick={() => buttonClick(service.id, link.linkText, service.name, data.name)}>
                                                                            {link.linkText}
                                                                        </a> :
                                                                        <a class="learn-button" rel="noopener noreferrer" onClick={() => buttonClickForEmail(link.linkUrl, service.id, link.linkText, service.name, data.name)}>
                                                                            {link.linkText}
                                                                        </a >
                                                                }
                                                            </>
                                                        ))}
                                                </div>
                                                <div className="right-button-container">
                                                    {service.links.filter(link => link.position === "Right")
                                                        .map(link => (
                                                            <>
                                                                {
                                                                    link.linkUrl.indexOf("javascript:window.open") === -1 ?
                                                                        <a class="request-now" rel="noopener noreferrer" href={link.linkUrl} target="_blank" onClick={() => buttonClick(service.id, link.linkText, service.name, data.name)}>
                                                                            {link.linkText}
                                                                        </a> :
                                                                        <a class="request-now" rel="noopener noreferrer" onClick={() => buttonClickForEmail(link.linkUrl, service.id, link.linkText, service.name, data.name)}>
                                                                            {link.linkText}
                                                                        </a>
                                                                }
                                                            </>
                                                        ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div >
                                </React.Fragment>
                            ))}
                        </div>
                    </div > :
                    <div className="mutliple-services">
                        {data.services.map(data => (
                            <div class="service">
                                <div class="heading">
                                    <Image className="service-icon" src={serviceIcon(data.name)} alt="service icon" />
                                    <span class="title">{data.name} ({data.services.length})</span>
                                    {
                                        data.tooltipInfo !== "" && data.tooltipInfo !== null && <div class="custom-tooltip">
                                            <Image data-tip data-for={data.id} className="tool-tip-icon" src={TipIcon} alt="Tool Tip Icon" />
                                        </div>
                                    }
                                    <Tooltip className={""} id={data.id} delayHide={0} position="top">{data.tooltipInfo}</Tooltip>
                                </div>
                                <div className="sub-services">
                                    {data.services.map((service, subServiceIndex) => (
                                        <React.Fragment key={service.id}>

                                            <div style={{ display: service.isExpanaded !== true ? 'flex' : 'none' }} className={`${service.highlightFlag === 1 ? "sub-service highlighted" : "sub-service"}`} onClick={() => serviceClick(service.id, index, subServiceIndex, 2, data.id, service.name, data.name)}>
                                                {service.highlightFlag === 1 && <Image className="new-service-badge" src={NewServiceBadge} alt="new-service-badge icon" />}
                                                <div class="sub-service-title">{service.name}</div>
                                                <Image className="arrow-down" src={ArrowDown} alt="Arrow Down" />
                                            </div >

                                            <div style={{ display: service.isExpanaded === true ? 'flex' : 'none' }} className="sub-service-expanded">
                                                <div class="sub-service-header" onClick={() => serviceClick(service.id, index, subServiceIndex, 2, data.id)}>
                                                    <span class="header-text">{service.name}</span>
                                                </div>
                                                <div class="sub-service-content">
                                                    <div class="sub-service-description">{ReactHtmlParser(service.description)}</div>
                                                    <div class="sub-service-links">
                                                        <div className="left-button-container">
                                                            {service.links.filter(link => link.position === "Left")
                                                                .map(link => (
                                                                    <>
                                                                        {
                                                                            link.linkUrl.indexOf("javascript:window.open") === -1 ?
                                                                                <a class="learn-button" rel="noopener noreferrer" href={link.linkUrl} target="_blank" onClick={() => buttonClick(service.id, link.linkText, service.name, data.name)}>
                                                                                    {link.linkText}
                                                                                </a> :
                                                                                <a class="learn-button" rel="noopener noreferrer" onClick={() => buttonClickForEmail(link.linkUrl, service.id, link.linkText, service.name, data.name)}>
                                                                                    {link.linkText}
                                                                                </a>
                                                                        }
                                                                    </>
                                                                ))}
                                                        </div>
                                                        <div className="right-button-container">
                                                            {service.links.filter(link => link.position === "Right")
                                                                .map(link => (
                                                                    <>
                                                                        {
                                                                            link.linkUrl.indexOf("javascript:window.open") === -1 ?
                                                                                <a class="request-now" rel="noopener noreferrer" href={link.linkUrl} target="_blank" onClick={() => buttonClick(service.id, link.linkText, service.name, data.name)}>
                                                                                    {link.linkText}
                                                                                </a> :
                                                                                <a class="request-now" rel="noopener noreferrer" onClick={() => buttonClickForEmail(link.linkUrl, service.id, link.linkText, service.name, data.name)}>
                                                                                    {link.linkText}
                                                                                </a>
                                                                        }
                                                                    </>
                                                                ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </React.Fragment>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
            }
        </div >
    );
};
